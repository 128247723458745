import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImage from './BackgroundImage';

const Background = ({ image, link, type, children }) => {
  return type === 'image' ? (
    <>
      <BackgroundImage link={link ? link : null} image={image} />
      {children}
    </>
  ) : (
    <div>{children}</div> //video
  );
};

export default Background;

Background.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};
