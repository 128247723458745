import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../assets/mealbox_logo_2.png';

const LogoWrapper = styled.div`
  margin-left: 10rem;
  width: 150px;
  position: relative;
  z-index: 2;
`;

const Logo = () => {
  return (
    <LogoWrapper>
      <img src={LogoImage} alt="Logo" />
    </LogoWrapper>
  );
};

export default Logo;
