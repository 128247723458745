import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import CardDemo from './CardDemo';
import useIsDemo from '../../hooks/useIsDemo';

const CardsWrapper = styled.div`
  width: 100%;
`;

const Cards = () => {
  const isDemo = useIsDemo();
  return (
    <CardsWrapper>
      {isDemo ? (
        <CardDemo title="Demo" description="This is a demo card" image="https://via.placeholder.com/150" />
      ) : (
        <Card title="Real" description="This is a real card" image="https://via.placeholder.com/150" />
      )}
    </CardsWrapper>
  );
};

export default Cards;
