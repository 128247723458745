import React from 'react';

const useIsDemo = () => {
  // Extract the current URL
  const currentUrl = window.location.href;

  // Check if 'demo' is in the URL
  const isDemo = currentUrl.includes('demo');
  return isDemo;
};

export default useIsDemo;
