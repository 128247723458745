import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { TfiClose } from 'react-icons/tfi';

import LogoImage from '../../assets/mealbox_logo_small.png';
import FoodoraImage from '../../assets/foodora.png';
import WoltImage from '../../assets/wolt.png';
import { deliveryLinks } from '../../data/deliveryLinks';

const CardWrapper = styled.div`
  color: white;
  width: 100%;
`;

const SingleCard = styled.div`
  padding: 20px;
  background: rgb(0, 0, 0);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.4598214285714286) 0%, rgba(32, 32, 35, 1) 51%);
  border-radius: 20px;
  border: 1px solid #3d3d3f;
  display: block;
  width: auto;
  transform: scale(0.9);
  &.featured {
    width: auto;
    .logo-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      h3 {
        background: #ba1516;
        padding: 10px 20px;
        border-radius: 12px;
      }
    }
  }
  .logo-title {
    display: block;
    margin-bottom: 20px;
    h3 {
      background: #ba1516;
      padding: 10px 20px;
      border-radius: 12px;
    }
  }
`;

const LogoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    background: #ba1516;
    padding: 10px 20px;
    border-radius: 12px;
  }
`;

const Address = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
`;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    paddingTop: 30,
  },
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 150px;
  img {
    cursor: pointer;
    max-width: 150px;
    &.inactive {
      filter: grayscale(100%);
      cursor: not-allowed;
    }
  }
`;

const ModalInfo = styled.div`
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 80px;
`;

const ModalClose = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 15px;
  span {
    font-size: 1.6rem;
    background: transparent;
    padding: 10px 30px;
    border-radius: 15px;
    border: 1px solid white;
    cursor: pointer;
    transition: all 0.4s ease;
    opacity: 0.5;
    svg {
      stroke: white;
      margin: 0 15px -2px 0;
      path {
        fill: white;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
`;

const Card = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userLocation, setUserLocation] = useState({});
  const [closestCity, setClosestCity] = useState(null);
  const [reorderedCities, setReorderedCities] = useState([]);

  // Extract the current URL
  const currentUrl = window.location.href;

  // Check if 'demo' is in the URL
  const isDemo = currentUrl.includes('demo');

  // Convert Degress to Radians
  function Deg2Rad(deg) {
    return (deg * Math.PI) / 180;
  }

  function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = Deg2Rad(lat1);
    lat2 = Deg2Rad(lat2);
    lon1 = Deg2Rad(lon1);
    lon2 = Deg2Rad(lon2);
    var R = 6371; // km
    var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    var y = lat2 - lat1;
    var d = Math.sqrt(x * x + y * y) * R;
    return d;
  }

  const cities = [
    {
      name: 'Fuzesabony',
      lat: 47.74971817060334,
      lng: 20.41551526700623,
      nicename: 'Füzesabony',
      address: 'Baross Gábor út, 8.',
    },
  ];

  var lat = userLocation.latitude; // user's latitude
  var lon = userLocation.longitude; // user's longitude

  function NearestCity(latitude, longitude) {
    var minDif = 99999;
    var closest;

    for (var index = 0; index < cities.length; ++index) {
      var dif = PythagorasEquirectangular(latitude, longitude, cities[index].lat, cities[index].lng);
      if (dif < minDif) {
        closest = index;
        minDif = dif;
      }
    }

    // echo the nearest city
    return cities[closest];
  }

  const filterCities = (array) => {
    const newArray = array.filter(function (obj) {
      return obj.nicename !== closestCity?.nicename;
    });
    setReorderedCities(newArray);
  };

  const openModal = (e) => {
    setIsOpen(true);
    setSelectedCity(e.target.textContent);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openSubpage = (e) => {
    const selectedCity = e.target.textContent;

    if (isDemo) {
      switch (selectedCity) {
        case 'Hatvan':
          window.open('https://hatvan.mealbox.hu/', '_blank');
          break;
        case 'Mezőkövesd':
          window.open('https://mezokovesd.mealbox.hu/', '_blank');
          break;
        case 'Füzesabony':
          window.open('https://fuzesabony.mealbox.hu/', '_blank');
          break;
        case 'Gyöngyös':
          window.open('https://gyongyos.mealbox.hu/', '_blank');
          break;
        default:
          break;
      }
    } else {
      switch (selectedCity) {
        case 'Hatvan':
          window.open('https://kfc.hu/', '_blank');
          break;
        case 'Mezőkövesd':
          window.open('https://www.mcdonalds.com/hu/hu-hu.html', '_blank');
          break;
        case 'Füzesabony':
          window.open('https://donpietro.hu/', '_blank');
          break;
        case 'Dunakeszi':
          window.open('https://lacorte.hu/wp53/', '_blank');
          break;
        case 'Gyöngyös':
          window.open('https://doncarlospizzeria.cpo.hu/doncarlospizzeria/etlap/diid-64/streetid-6511/ss-1/', '_blank');
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(getLatLon);
    function getLatLon(position) {
      var latitude = position.coords.latitude;
      var longitude = position.coords.longitude;
      setUserLocation({ latitude, longitude });
    }
  }, []);

  useEffect(() => {
    setClosestCity(NearestCity(userLocation?.latitude, userLocation?.longitude));
  }, [userLocation]);

  useEffect(() => {
    filterCities(cities);
  }, [closestCity]);

  return (
    <>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
          <ModalInfo>Kérjük válassz, honnan szeretnél rendelni:</ModalInfo>
          <ModalContent>
            <ModalColumn>
              <a
                href={
                  deliveryLinks.find((obj) => obj.city === selectedCity).links.foodora
                    ? deliveryLinks.find((obj) => obj.city === selectedCity).links.foodora
                    : null
                }
              >
                <img
                  className={!deliveryLinks.find((obj) => obj.city === selectedCity).links.foodora ? 'inactive' : ''}
                  src={FoodoraImage}
                  alt="Foodora"
                />
              </a>
            </ModalColumn>
            <ModalColumn>
              <a
                href={
                  deliveryLinks.find((obj) => obj.city === selectedCity).links.wolt
                    ? deliveryLinks.find((obj) => obj.city === selectedCity).links.wolt
                    : null
                }
              >
                <img
                  className={!deliveryLinks.find((obj) => obj.city === selectedCity).links.wolt ? 'inactive' : ''}
                  src={WoltImage}
                  alt="Wolt"
                />
              </a>
            </ModalColumn>
          </ModalContent>
          <ModalClose>
            <span onClick={closeModal}>
              <TfiClose color="#ffffff" size={24} />
              Bezár
            </span>
          </ModalClose>
        </Modal>
      )}
      <CardWrapper>
        <div className="angry-grid">
          {/* <div id="item-0">
          <SingleCard className="featured-card">
            <div className="logo-title">
              <img src={LogoImage} alt="Mealbox Logo" />
              <h3>Mezőkövesd</h3>
            </div>
            <Address>Egri Út 51. (Tesco)</Address>
          </SingleCard>
        </div> */}
          {/* <div id="item-1">
          <SingleCard>
            <div className="logo-title">
              <img src={LogoImage} alt="Mealbox Logo" />
              <h3>Hatvan</h3>
            </div>
            <Address>Egri Út 51. (Tesco)</Address>
          </SingleCard>
        </div>
        <div id="item-2">
          <SingleCard>
            <div className="logo-title">
              <img src={LogoImage} alt="Mealbox Logo" />
              <h3>Füzesabony</h3>
            </div>
            <Address>Egri Út 51. (Tesco)</Address>
          </SingleCard>
        </div>
        <div id="item-3">
          <SingleCard>
            <div className="logo-title">
              <img src={LogoImage} alt="Mealbox Logo" />
              <h3>Gyöngyös</h3>
            </div>
            <Address>Egri Út 51. (Tesco)</Address>
          </SingleCard>
        </div>
        <div id="item-4">
          <SingleCard>
            <div className="logo-title">
              <img src={LogoImage} alt="Mealbox Logo" />
              <h3>Dunakeszi</h3>
            </div>
            <Address>Egri Út 51. (Tesco)</Address>
          </SingleCard>
        </div> */}
          {closestCity && (
            <div className="featured-card">
              <SingleCard
                className="featured-card"
                onClick={
                  closestCity?.nicename === 'Dunakeszi' || closestCity?.nicename === 'Gyöngyös'
                    ? (e) => openSubpage(e)
                    : (e) => openSubpage(e)
                }
              >
                <div className="logo-title">
                  <img src={LogoImage} alt="Mealbox Logo" />
                  <h3>{closestCity.nicename}</h3>
                </div>
                <Address>{closestCity.address}</Address>
              </SingleCard>
            </div>
          )}
          {reorderedCities.map((city, index) => (
            <div key={index} className={`grid-${index}`}>
              <SingleCard
                onClick={
                  city.nicename === 'Dunakeszi' || city?.nicename === 'Gyöngyös'
                    ? (e) => openSubpage(e)
                    : (e) => openSubpage(e)
                }
              >
                <div className="logo-title">
                  <img src={LogoImage} alt="Mealbox Logo" />
                  <h3>{city.nicename}</h3>
                </div>
                <Address>{city.address}</Address>
              </SingleCard>
            </div>
          ))}
        </div>

        {/* <SingleCard className="featured">
        <div className="logo-title">
          <img src={LogoImage} alt="Mealbox Logo" />
          <h3>Mezőkövesd</h3>
        </div>
        <Address>Egri Út 51. (Tesco)</Address>
      </SingleCard>
      <SingleCard>
        <div className="logo-title">
          <img src={LogoImage} alt="Mealbox Logo" />
          <h3>Mezőkövesd</h3>
        </div>
        <Address>Egri Út 51. (Tesco)</Address>
      </SingleCard>
      <SingleCard>
        <div className="logo-title">
          <img src={LogoImage} alt="Mealbox Logo" />
          <h3>Mezőkövesd</h3>
        </div>
        <Address>Egri Út 51. (Tesco)</Address>
      </SingleCard> */}
      </CardWrapper>
    </>
  );
};

export default Card;
