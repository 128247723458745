import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: black;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }

  .desktop {
    @media (max-width: 1199px) {
        display: none !important;
    }
    @media (min-width: 993px) {
        display: flex !important;
    }
  }

  .mobile {
    @media (max-width: 1199px) {
        display: flex;
        overflow: hidden;
    }
    @media (min-width: 993px) {
        display: none;
    }
  }

  .angry-grid {
    margin-top: 20vh;
   display: grid; 

   grid-template-rows: 1fr 1fr 1fr;

   
   gap: 0;
  
   
}
  
.featured-card {

  
   grid-row-start: 1;
   grid-column-start: 1;

   grid-row-end: 2;
   grid-column-end: 3;
   div {
    margin-top: 30px
   }
}
.grid-0 {

  
   grid-row-start: 2;
   grid-column-start: 1;

   grid-row-end: 3;
   grid-column-end: 2;
   
}
.grid-1 {

   
   grid-row-start: 2;
   grid-column-start: 2;

   grid-row-end: 3;
   grid-column-end: 3;
   
}
.grid-2 {

  
   grid-row-start: 3;
   grid-column-start: 1;

   grid-row-end: 4;
   grid-column-end: 2;
   
}
.grid-3 {

  
   grid-row-start: 3;
   grid-column-start: 2;

   grid-row-end: 4;
   grid-column-end: 3;
   
}
`;

export default GlobalStyles;
