import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const SliderWrapper = styled.div`
  img {
    border-radius: 10px;
  }
`;

const Element = styled.div`
  height: 400px;
  width: 100%;
  @media (max-width: 1199px) {
    height: auto;
    border-radius: 30px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
    @media (max-width: 1199px) {
      height: 200px !important;
    }
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
    @media (max-width: 1199px) {
      height: auto !important;
      border-radius: 30px;
    }
  }
`;

const imageStyle = {
  height: 'auto',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '30px',
};

const Slider = ({ sliderImages }) => {
  const [sliderElements, setSliderElements] = useState([]);

  useEffect(() => {
    const sliderElements = sliderImages.map((element) => {
      return element.fields.file;
    });
    setSliderElements(sliderElements);
  }, [sliderImages]);

  return (
    <SliderWrapper>
      <Carousel autoPlay showThumbs={false} showStatus={false} swipeable infiniteLoop stopOnHover interval="10000">
        {sliderElements.map((element, index) => (
          <Element key={index}>
            {element.contentType.includes('video') ? (
              <video controls autoPlay playsInline muted>
                <source src={element.url} type="video/mp4" />
                <source src={element.url} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={element.url} alt={element} style={imageStyle} />
            )}
          </Element>
        ))}
      </Carousel>
    </SliderWrapper>
  );
};

export default Slider;

Slider.propTypes = {
  sliderImages: PropTypes.array,
};
