import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: url(${(props) => props.image}) no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  @media (max-width: 1199px) {
    height: auto;
  }
`;

const LinkWrapper = styled.a`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 1;
`;

const BackgroundImage = ({ image, link }) => {
  return link ? (
    <LinkWrapper href={link}>
      <BackgroundWrapper image={image} />
    </LinkWrapper>
  ) : (
    <BackgroundWrapper image={image} />
  );
};

export default BackgroundImage;

BackgroundImage.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
};
