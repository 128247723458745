import { useState, useEffect } from 'react';
import './App.css';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from './components/Layout/Layout';
import Background from './Background/Background';
import Header from './components/Header/Header';
import Selector from './components/Selector/Selector';

import { Helmet } from 'react-helmet';

const client = createClient({
  // eslint-disable-next-line no-undef
  space: process.env.REACT_APP__CONTENTFUL_SPACE_ID,
  // eslint-disable-next-line no-undef
  accessToken: process.env.REACT_APP__CONTENTFUL_ACCESS_KEY,
});

function App() {
  const [homePage, setHomePage] = useState();

  useEffect(() => {
    client.getEntry('37plzolzoZlnAPHulgBdbD').then((response) => {
      setHomePage(response);
    });
  }, []);

  return (
    <div className="App">
      {homePage && (
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Meal boX - Házhozszállítás</title>
            <link rel="canonical" href="https://mealbox.hu" />
            <meta name="description" content="Meal boX - Házhozszállítás" />
            {/* End standard metadata tags */}
            {/* Facebook tags */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Meal boX - Házhozszállítás" />
            <meta property="og:description" content="Meal boX - Házhozszállítás" />
            <meta property="og:image" content="https://mealbox.hu/__00Social/share.png" />
            {/* End Facebook tags */}
          </Helmet>
          <Background
            image={`https:${homePage.fields.background.fields.file.url}`}
            link={homePage.fields.link}
            type="image"
          >
            <Header />
            <Selector
              sliderImages={homePage.fields.slider}
              introText={documentToReactComponents(homePage.fields.introText)}
            />
            {/* <Map /> */}
          </Background>
          {/* <Map /> */}
        </Layout>
      )}
    </div>
  );
}

export default App;
