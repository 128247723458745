export const deliveryLinks = [
  {
    id: 0,
    city: 'Dunakeszi',
    links: {
      foodora: 'https://www.foodora.hu/restaurant/x6jo/mealbox-dunakeszi',
      wolt: 'https://wolt.com/hu/hun/dunakeszi/restaurant/mealbox-gyorsetterem',
    },
  },
  {
    id: 1,
    city: 'Gyöngyös',
    links: {
      foodora: 'https://www.foodora.hu/restaurant/hh0a/mealbox-gyongyos',
      wolt: '',
    },
  },
];
