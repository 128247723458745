import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IntroTextWrapper = styled.div`
  color: white;
  font-size: 1.3rem;
  margin-bottom: 80px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 1199px) {
    width: 90%;
  }
`;

const IntroText = ({ text }) => {
  return <IntroTextWrapper>{text}</IntroTextWrapper>;
};

export default IntroText;

IntroText.propTypes = {
  text: PropTypes.any,
};
