import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MapImage from '../../assets/map.png';
import Slider from '../Slider/Slider';
import Map from '../Map/Map';
import MapDemo from '../Map/MapDemo';

import useIsDemo from '../../hooks/useIsDemo';

const BoxWrapper = styled.div`
  width: 800px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(44, 44, 44, 0.15);
  box-shadow: 0 8px 32px 0 rgba(49, 49, 49, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 10px;
  font-size: 4rem;
  font-weight: 700;
  color: white;
  overflow: hidden;
  margin-bottom: 60px;
  position: relative;
  img {
    height: auto;
    width: 100%;
    border-radius: 40px;
  }
`;

const Box = ({ type, sliderImages }) => {
  const isDemo = useIsDemo();
  return (
    <BoxWrapper>{type === 'map' ? isDemo ? <MapDemo /> : <Map /> : <Slider sliderImages={sliderImages} />}</BoxWrapper>
  );
};

export default Box;

Box.propTypes = {
  type: PropTypes.string,
  sliderImages: PropTypes.array,
};
