import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from './globalStyles';
import styled from 'styled-components';

const LayoutWrapper = styled.div``;

export const Main = styled.main`
  margin: 0 auto;
  max-width: 1650px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 90vh;
`;

export const MainMobile = styled.main`
  margin: 0 auto;
  max-width: 1650px;
  width: 100%;
`;

export const MainWide = styled.div`
  margin: 0 auto;
  max-width: 1650px;
  width: 100%;
  height: 100%;
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <LayoutWrapper>{children}</LayoutWrapper>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};
