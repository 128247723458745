import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Main, MainMobile } from '../Layout/Layout';
import Box from '../Box/Box';
import IntroText from '../IntroTex/IntroText';
import Cards from '../Cards/Cards';
import Slider from '../Slider/Slider';

const SelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
`;

const Selector = ({ sliderImages, introText }) => {
  return (
    <>
      <SelectorWrapper>
        <Main className="desktop">
          <Box type="map" />
          <Box sliderImages={sliderImages} />
        </Main>
        <MainMobile className="mobile">
          <Cards />
        </MainMobile>
      </SelectorWrapper>
      <IntroText text={introText} />
      <MobileWrapper className="mobile">
        <Slider style={{ padding: '15px', marginBottom: 15 }} sliderImages={sliderImages} />
      </MobileWrapper>
    </>
  );
};

export default Selector;

Selector.propTypes = {
  sliderImages: PropTypes.array,
  introText: PropTypes.any,
};
