import React from 'react';
import styled from 'styled-components';
import { MainWide } from '../Layout/Layout';
import Logo from './Logo';

const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  text-align: left;
  height: 100px;
  left: 0;
  z-index: 2;
  color: white;
`;

const Header = () => {
  return (
    <HeaderWrapper className="desktop">
      <MainWide>
        <Logo />
      </MainWide>
    </HeaderWrapper>
  );
};

export default Header;
